import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'

export default function ImageWall() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="s" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='l'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_01.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera View'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Enter the Multiview Menu and click the + button in the top right corner to add your camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='m'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_02.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Add a Camera'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Choose Add a New Camera to add your camera via it&apos;s UID.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_03.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Add new P2P Camera'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          To use your cameras P2P service select New p2p Camera.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_04.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='QR Code Scanner'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          It is recommended to use the QRCode Reader to scan the code inside your camera&apos;s webUI. Alternatively, search for your camera on the network or type in the UID manually...
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_05.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Select Camera Model'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Choose p2p camera for static models and PTZ for Pan-Tilt&Zoom camera models.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_06.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Search for Cameras'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          If you used the Search Function select your camera from the list of found P2P devices on your local network...
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiSpacer />

        <EuiFlexItem>
          <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_07.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera User ID'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          ...Or type in the UID found in your cameras webUI manually.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_08.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Login'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Type in the admin password for your camera and click next to proceed.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_09.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Name'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Select a name for your camera. Each camera you add has to have a unique name.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_10.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Overview'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        The overview page allows you to make final changes and add additional information - e.g. your cameras local IP that can be used when you are in the same network as your camera. Note that not all routers allow you to connect via the DDNS address from inside your local network. You might have to disconnect your phone from the local wifi to test the remote access.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/P2P/ImageWall/InstarVision_Android_P2P_11.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Verification'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                          Verify that all details are correct and click test to let the app test the connection to your camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}