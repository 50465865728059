import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class P2PTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "Feature" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { featureColumn: 'DDNS or P2P', descriptionColumn: 'First you have to choose to add your camera via its local IP / DDNS address or its P2P UID. For the DDNS Service please Continue Reading Here.' },
        { featureColumn: 'UID', descriptionColumn: '[03] You can either search for P2P cameras on your local network (your smartphone has to be connected to your home router´s WiFi network) or scan the QRcode from the P2P menu- it will automatically fill out all the necessary information to add your camera. You can also write down your camera´s UID (User Identification) from the P2P menu and type it in manually.' },
        { featureColumn: 'Camera Name', descriptionColumn: '[06] You can choose a name for your camera - the name does not have to be identical with the camera name inside the web user interface. But every camera you add has to have a unique name.' },
        { featureColumn: 'User Name', descriptionColumn: '[07] To access your camera you will need to provide the admin username you use to log in to your camera`s web user interface. The default username for all FHD, HD and VGA cameras is admin' },
        { featureColumn: 'Password', descriptionColumn: '[08] Provide the password you use to log in to your camera`s web user interface. The default password is instar for HD cameras and no password for VGA cameras. You were asked to change the default password during the initial installation in case of FHD cameras.' },
        { featureColumn: 'Test/Add', descriptionColumn: '[09] Afterwards click on Test to check your configuration. Click on Save - Diskette Symbol in the top right - once the test was successful. Go to the Multiview Menu o access your cameras live video stream.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

P2PTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(P2PTable)